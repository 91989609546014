<template>
  <v-card id="account-setting-card">
    <div id="Music" style="padding: 30px">
      <h1>Music</h1>
      <br />
      <v-switch v-model="switch1" inset :label="`Want to play Music ?`"></v-switch>
      <v-col class="d-flex" cols="12" sm="12">
        <v-select
          v-model="selected"
          :disabled="!switch1"
          :items="notificationValues"
          label="Select Music"
          @change="myD"
          outlined
        ></v-select>
      </v-col>
      <v-form v-model="valid" ref="form" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-text-field
                v-model="musicUrl"
                :disabled="!switch1"
                :rules="musicRules"
                :counter="100"
                label="Music URL"
                required
              ></v-text-field>

              <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Save & Play</v-btn>

              <v-btn color="error" class="mr-4" @click="reset">Reset</v-btn>
              <v-btn color="error" class="mr-4" @click="test">Reset</v-btn>
            </v-col>
          </v-row>

          <v-flex xs12 sm6>
            <v-select
              v-bind:items="items"
              v-model="e11"
              label="Devices"
              outlined
              prepend-inner-icon="mdi-view-dashboard"
              item-text="title"
              item-value="title"
              max-height="auto"
              autocomplete
            >
              <template slot="selection">{{ items.title }} - {{ items.subtitle }}</template>
              <template slot="items">
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </v-flex>

          <v-col style="width: 400px" class="pt-4 mt-6">
            <v-select
              v-bind:items="items"
              v-model="e11"
              label="Devices"
              outlined
              prepend-inner-icon="mdi-view-dashboard"
              item-text="title"
              item-value="title"
              max-height="auto"
              autocomplete
            >
              <v-list>
                <!-- <v-subheader>REPORTS</v-subheader> -->
                <!-- <v-list-item-group v-model="item" color="primary"> -->
                <v-list-item v-for="(item, i) in items" :key="i">
                  <v-list-item-icon>
                    <v-icon v-text="item.icon"></v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.text"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- </v-list-item-group> -->
              </v-list>

              <!-- <template slot="selection">
                  {{ item.title }} - {{item.subtitle}}
                </template>

            <v-list-tile-content v-text="items.name"></v-list-tile-content>
             <v-list-item-avatar >
                      <v-img :src="item.avatar"></v-img>
                    </v-list-item-avatar>
                    <v-list-tile-content>
                      <v-list-tile-title v-html="items.title"></v-list-tile-title>
                      <v-list-tile-sub-title v-html="items.subtitle"></v-list-tile-sub-title>
            </v-list-tile-content>-->
              <!-- <v-list-item v-for="(item, i) in items" :key="i" :inactive="inactive">
              <v-list-item-avatar v-if="avatar">
                <v-img :src="item.avatar"></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
                <v-list-item-subtitle v-if="twoLine || threeLine" v-html="item.subtitle"></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>-->
            </v-select>
            <v-row align="center">
              <v-row justify="space-around">
                <v-switch v-model="disabled" class="ma-2" label="Disabled"></v-switch>
                <v-switch v-model="dense" class="ma-2" label="Dense"></v-switch>
                <v-switch v-model="twoLine" class="ma-2" label="Two-line"></v-switch>
                <v-switch v-model="threeLine" class="ma-2" label="Three-line"></v-switch>
                <v-switch v-model="shaped" class="ma-2" label="Shaped"></v-switch>
                <v-switch v-model="flat" class="ma-2" label="Flat"></v-switch>
                <v-switch v-model="subheader" class="ma-2" label="Subheader"></v-switch>
                <v-switch v-model="inactive" class="ma-2" label="Inactive"></v-switch>
                <v-switch v-model="subGroup" class="ma-2" label="Sub-group"></v-switch>
                <v-switch v-model="nav" class="ma-2" label="Nav"></v-switch>
                <v-switch v-model="avatar" class="ma-2" label="Avatar"></v-switch>
                <v-switch v-model="rounded" class="ma-2" label="Rounded"></v-switch>
              </v-row>
              <v-card class="mx-auto" max-width="400" tile>
                <v-list
                  :disabled="disabled"
                  :dense="dense"
                  :two-line="twoLine"
                  :three-line="threeLine"
                  :shaped="shaped"
                  :flat="flat"
                  :subheader="subheader"
                  :sub-group="subGroup"
                  :nav="nav"
                  :avatar="true"
                  :rounded="rounded"
                >
                  <v-subheader>REPORTS</v-subheader>
                  <v-list-item-group v-model="item" color="primary">
                    <v-list-item v-for="(item, i) in items" :key="i" :inactive="inactive">
                      <v-list-item-avatar v-if="avatar">
                        <v-img :src="item.avatar"></v-img>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title v-html="item.title"></v-list-item-title>
                        <v-list-item-subtitle v-if="twoLine || threeLine" v-html="item.subtitle"></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-card>
            </v-row>
          </v-col>
          <v-snackbar v-model="snackbar" :timeout="timeout" :right="x === 'right'" :top="y === 'top'" :color="color">
            {{ text }}
            <v-btn color="blue" text @click="snackbar = false">Close</v-btn>
          </v-snackbar>
        </v-container>
      </v-form>
    </div>
  </v-card>
</template>
<style scoped>
</style>
<script>
// import { appConfig, theUserDevicesList } from "../../firebaseConfig";
// import { store } from '../../store';
export default {
  name: 'Music',
  data() {
    return {
      e11: [],
      valid: false,
      switch1: false,
      search: '',
      musicUrl: '',
      musicRules: [v => !!v || 'Music URL is required', v => /\.(?:wav|mp3)$/i.test(v) || 'Music must be valid'],
      selected: null,
      notificationValues: ['Warriyo', 'LostSky', 'Jasico', 'Electro'],
      myitems: [],
      latitude: '',
      longitude: '',
      obj: {
        appVibration: {
          seconds: '',
          vibration: '',
        },
        battryPercentage: '',
        isCharging: '',
        bluetooth: '',
        brightness: '',
        flashlight: '',
        mobileData: '',
        mobileDataType: '',
        musicUrl: {
          music: '',
          url: '',
        },
        sound: '',
      },
      color: '',
      mode: '',
      snackbar: false,
      text: "Hello, I'm a snackbar",
      timeout: 6000,
      x: null,
      y: 'top',
      items: [
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle:
            "<span class='text--primary'>Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: 'Summer BBQ <span class="grey--text text--lighten-1">4</span>',
          subtitle:
            "<span class='text--primary'>to Alex, Scott, Jennifer</span> &mdash; Wish I could come, but I'm out of town this weekend.",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Oui oui',
          subtitle:
            "<span class='text--primary'>Sandra Adams</span> &mdash; Do you have Paris recommendations? Have you ever been?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/4.jpg',
          title: 'Birthday gift',
          subtitle:
            "<span class='text--primary'>Trevor Hansen</span> &mdash; Have any ideas about what we should get Heidi for her birthday?",
        },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/5.jpg',
          title: 'Recipe to try',
          subtitle:
            "<span class='text--primary'>Britta Holt</span> &mdash; We should eat this: Grate, Squash, Corn, and tomatillo Tacos.",
        },
      ],
      item: 5,
      disabled: false,
      dense: false,
      twoLine: false,
      threeLine: false,
      shaped: false,
      flat: false,
      subheader: false,
      inactive: false,
      subGroup: false,
      nav: false,
      avatar: false,
      rounded: false,
    }
  },
  mounted: function () {},
  methods: {
    validate() {
      this.obj.musicUrl.music = this.switch1
      if (this.switch1 == false) {
        this.obj.musicUrl.url = null
      } else {
        if (this.selected != null) {
          if (this.selected == 'Warriyo') {
            this.obj.musicUrl.url = 'https://www.dropbox.com/s/qq272qvgpvsqa3f/Warriyo%20-%20Mortals.mp3?dl=1'
            console.log(this.selected)
          } else if (this.selected == 'LostSky') {
            this.obj.musicUrl.url =
              'https://www.dropbox.com/s/frqyqu9ykyopc05/Lost%20Sky%20-%20Fearless%20%5BNCS%20Release%5D.mp3?dl=1'
            console.log(this.selected)
          } else if (this.selected == 'Jasico') {
            this.obj.musicUrl.url = 'https://audiograb.com/songs/jarico-islandncsbestof-1Mm2M6Qccq.mp3'
            console.log(this.selected)
          } else if (this.selected == 'Electro') {
            this.obj.musicUrl.url =
              'https://www.dropbox.com/s/2giv46b72vlqtl9/Electro-Light%20-%20Symbolism%20_NCS%20Release_.mp3?dl=1'
            console.log(this.selected)
          }
        } else {
          this.obj.musicUrl.url = this.musicUrl
        }
      }

      console.log(this.obj)
    },
    reset() {
      this.$refs.form.reset()
    },
    resetValidation() {
      this.$refs.form.resetValidation()
    },
  },
}
</script>